<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="gray py-0">
        <template v-if="is_loaded">
          <type
            v-if="component == 'type'"
            :properties="properties"
            :pagination="pagination"
            :regions="regions"
            :categories="categories"
            :amenities="amenities"
          />
          <category
            v-if="component == 'category'"
            :properties="properties"
            :pagination="pagination"
            :regions="regions"
            :categories="categories"
            :amenities="amenities"
          />
          <region
            v-if="component == 'region'"
            :properties="properties"
            :pagination="pagination"
            :regions="regions"
            :categories="categories"
            :amenities="amenities"
          />
          <property
            v-if="component == 'property'"
            :property="property"
          />
          <agency
            v-if="component == 'agency'"
            :pagination="pagination"
            :properties="properties"
            :agency="agency"
            :featured="featured"
            :agents="agents"
          />
          <user
            v-if="component == 'user'"
            :pagination="pagination"
            :properties="properties"
            :user="user"
          />
          <error-404 v-if="component == 'error'" />
        </template>
        <template v-if="!is_loaded">
          <loading />
        </template>
      </section>

      <!-- ============================ Call To Action ================================== -->
      <section class="theme-bg call-to-act-wrap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="call-to-act">
                <div class="call-to-act-head">
                  <h3>Post your property on RealHub</h3>
                  <span
                    >We'll help you get to potential clients much faster.</span
                  >
                </div>
                <router-link :to="{ name: 'submit-property' }" class="btn btn-call-to-act"
                  >SignUp Today</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Call To Action End ================================== -->

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import Type from './page/Type.vue'
import Category from './page/Category.vue'
import Region from './page/Region.vue'
import Property from './page/Property.vue'
import Agency from './page/Agency.vue'
import User from './page/User.vue'
import Error404 from './page/404.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    Type,
    Error404,
    Category,
    Region,
    Property,
    Agency,
    User
  },

  mounted() {
    this.initUrl();
  },

  data() {
    return {
      component: null,
      fetch_url: "",
      properties: [],
      is_loaded: false,
      pagination: { page: 1, total: 10, par_page: 10 },
      regions: [],
      categories: [],
      amenities: [],
      property: {},
      agency: {},
      user: {},
      featured: [],
      agents: []
    };
  },

  methods: {
    initUrl() {
      switch (this.$route.params.slug) {
        case "buy":
          this.fetch_url = `/api/v1/properties?type=buy`;
          this.fetch();
          break;

        case "rent":
          this.fetch_url = `/api/v1/properties?type=rent`;
          this.fetch();
          break;

        case "bnb":
          this.fetch_url = `/api/v1/properties?type=bnb`;
          this.fetch();
          break;

        case "sharespace":
          this.fetch_url = `/api/v1/properties?type=sharespace`;
          this.fetch();
          break;

        case "all":
          this.fetch_url = `/api/v1/properties?`;
          this.fetch();
          break;

        case "discover":
          this.fetch_url = `/api/v1/properties?sort=random`;
          this.fetch();
          break;

        case "regions":
          this.fetch_url = `/api/v1/properties/regions?`;
          this.fetch();
          break;

        default:
          this.fetch_url = `/api/v1/properties/${this.$route.params.slug}?`;
          this.fetch();
          break;
      }
    },

    fetch() {
      this.is_loaded = false;
      let url = this.fetch_url

      if(this.$route.query.page) {
        url += `&page=${this.$route.query.page}`
      }

      if(this.$route.query.type) {
        url += `&type=${this.$route.query.type}`
      }

      if(this.$route.query.category) {
        url += `&category=${this.$route.query.category}`
      }

      if(this.$route.query.region) {
        url += `&region=${this.$route.query.region}`
      }

      if(this.$route.query.search) {
        url += `&search=${this.$route.query.search}`
      }

      if(this.$route.query.bedrooms) {
        url += `&bedrooms=${this.$route.query.bedrooms}`
      }

      if(this.$route.query.min_price) {
        url += `&min_price=${this.$route.query.min_price}`
      }

      if(this.$route.query.max_price) {
        url += `&max_price=${this.$route.query.max_price}`
      }

      if(this.$route.query.amenities) {
        url += `&amenities=${this.$route.query.amenities}`
      }

      if(this.$route.query.sort) {
        url += `&sort=${this.$route.query.sort}`
      }

      this.$axios.get(url).then((response) => {
        switch (response.data.component) {
          case 'type':
            this.component = 'type'
            this.properties = response.data.properties;
            this.regions = response.data.regions;
            this.amenities = response.data.amenities;
            this.categories = response.data.categories;
            this.pagination = response.data.pagination;
            document.title = `RealHub Kenya: Browse the Latest Selection of ${this.pagination.title} - Prime Locations and Affordable Prices`
            break;

          case 'category':
            this.component = 'category'
            this.properties = response.data.properties;
            this.regions = response.data.regions;
            this.amenities = response.data.amenities;
            this.categories = response.data.categories;
            this.pagination = response.data.pagination;
            document.title = this.pagination.title
            break;

          case 'region':
            this.component = 'region'
            this.properties = response.data.properties;
            this.regions = response.data.regions;
            this.amenities = response.data.amenities;
            this.categories = response.data.categories;
            this.pagination = response.data.pagination;
            document.title = `RealHub Kenya: Browse the Latest Selection of Properties in ${this.pagination.title} - Prime Locations and Affordable Prices`
            break;

          case 'property':
            this.component = 'property'
            this.property = response.data.property;
            document.title = `RealHub Kenya: ${this.property.name}`
            break;

          case 'agency':
            this.component = 'agency'
            this.agency = response.data.agency;
            this.properties = response.data.properties;
            this.pagination = response.data.pagination;
            this.featured = response.data.featured;
            this.agents = response.data.agents;
            document.title = `RealHub Kenya: Find the Top Real Estate Agencies - Expertise and Professionalism Guaranteed`
            break;

          case 'user':
            this.component = 'user'
            this.user = response.data.user;
            this.properties = response.data.properties;
            this.pagination = response.data.pagination;
            break;
        
          default:
            this.component = 'error'
            break;
        }
        
        this.is_loaded = true;
      }).catch(() => {
        this.component = 'error'
        this.is_loaded = true;
      });
    },
  },

  computed: {
    full_path() {
      return this.$route.fullPath;
    },
  },

  watch: {
    full_path() {
      this.initUrl()
    },
  },
};
</script>
