<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="filter_search_opt">
          <a href="#" @click.prevent="openFilterSearch"
            >View Details<i class="ml-2 ti-menu"></i
          ></a>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- property Sidebar -->
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div
          class="simple-sidebar sm-sidebar"
          id="filter_search"
          style="left: 0"
        >
          <div class="search-sidebar_header">
            <h4 class="ssh_heading">Close</h4>
            <button
              @click.prevent="closeFilterSearch"
              class="w3-bar-item w3-button w3-large"
            >
              <i class="lni lni-close"></i>
            </button>
          </div>

          <!-- Find New Property -->
          <div class="sidebar-widgets">
            <div class="search-inner p-0">
              <div class="profile text-center">
                <img
                  :src="agency.logo"
                  :alt="agency.name"
                  style="width: 70%"
                  class="rounded"
                />
                <h4 class="mt-3">{{ agency.name }}</h4>
                <p class="mb-5">
                  <small class="text-muted float-start"
                    ><strong>Last Seen:</strong>{{ agency.last_seen }}</small
                  >
                  <small class="text-muted float-end"
                    ><strong>Joined:</strong>{{ agency.joined }}</small
                  >
                </p>
              </div>
              <div class="col-md-12 col-sm-12">
                <!-- Like And Share -->
                <div class="like_share_wrap">
                  <div class="mb-2">
                    <div class="d-grid mb-2" v-if="agency.phone_number">
                      <a
                        href="#"
                        @click.prevent=""
                        class="btn btn-facebook rounded"
                      >
                        <i class="lni lni-phone mr-2"></i>
                        <span>{{
                          agency.phone_number
                        }}</span>
                      </a>
                    </div>
                    <div
                      class="d-grid mb-2"
                      v-if="agency.whatsapp"
                    >
                      <a
                        href="#"
                        @click.prevent=""
                        class="btn btn-whatsapp rounded"
                      >
                        <i class="lni lni-whatsapp mr-2"></i>
                        <span>{{
                          agency.whatsapp
                        }}</span>
                      </a>
                    </div>
                    <div class="d-grid mb-2" v-if="agency.email">
                      <a
                        href="#"
                        @click.prevent=""
                        class="btn btn-google rounded"
                      >
                        <i class="lni lni-envelope mr-2"></i>
                        <span>{{
                          agency.email
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <h5 class="text-muted mt-3">Share</h5>
                  <div id="social-sharing-b">
                    <ShareNetwork
                      network="facebook"
                      :url="`https://realhub.co.ke${$route.fullPath}`"
                      :title="agency.name"
                      :description="agency.name"
                      :quote="agency.name"
                      :hashtags="`${agency.name}`"
                    >
                      <i class="ti ti-facebook"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="twitter"
                      :url="`https://realhub.co.ke${$route.fullPath}`"
                      :title="agency.name"
                      :description="agency.name"
                      :quote="agency.name"
                      :hashtags="`${agency.name}`"
                    >
                      <i class="ti ti-twitter"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="email"
                      :url="`https://realhub.co.ke${$route.fullPath}`"
                      :title="agency.name"
                      :description="agency.name"
                      :quote="agency.name"
                      :hashtags="`${agency.name}`"
                    >
                      <i class="ti ti-email"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="whatsapp"
                      :url="`https://realhub.co.ke${$route.fullPath}`"
                      :title="agency.name"
                      :description="agency.name"
                      :quote="agency.name"
                      :hashtags="`${agency.name}`"
                    >
                      <i class="lni lni-whatsapp"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="sms"
                      :url="`https://realhub.co.ke${$route.fullPath}`"
                      :title="agency.name"
                      :description="agency.name"
                      :quote="agency.name"
                      :hashtags="`${agency.name}`"
                    >
                      <i class="lni lni-bubble"></i>
                    </ShareNetwork>
                    <ShareNetwork
                      network="telegram"
                      :url="`https://realhub.co.ke${$route.fullPath}`"
                      :title="agency.name"
                      :description="agency.name"
                      :quote="agency.name"
                      :hashtags="`${agency.name}`"
                    >
                      <i class="lni lni-telegram"></i>
                    </ShareNetwork>
                  </div>
                </div>

                <div class="details-sidebar">
                  <!-- Agent Detail -->
                  <div class="sides-widget">
                    <div class="sides-widget-header">
                      <div class="sides-widget-details">
                        <span
                          ><i class="lni lni-envelope"></i>Send Message</span
                        >
                      </div>
                      <div class="clearfix"></div>
                    </div>

                    <form @submit.prevent="sendMessage">
                      <div class="sides-widget-body simple-form">
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Your Email"
                            v-model="message.email"
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label>Phone No.</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Your Phone"
                            v-model="message.phone_number"
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label>Message</label>
                          <textarea
                            class="form-control"
                            v-model="message.message"
                            placeholder="I'm interested in this property."
                            required
                          ></textarea>
                        </div>
                        <button class="btn btn-black btn-md rounded full-width">
                          Send Message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Sidebar End -->
      </div>

      <div class="col-lg-8 col-md-12 list-layout">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <div class="item-shorting-box">
              <div class="item-shorting clearfix">
                <div class="left-column pull-left">
                  <h4 class="m-0">
                    Showing
                    {{
                      pagination.total > 0 ? (pagination.page - 1) * 10 + 1 : 0
                    }}-{{
                      (pagination.page - 1) * 10 + 10 >= pagination.total
                        ? pagination.total
                        : (pagination.page - 1) * 10 + 10
                    }}
                    of {{ pagination.total }} Properties
                  </h4>
                </div>
              </div>
              <div class="item-shorting-box-right" v-if="pagination.total > 1">
                <div class="shorting-by text-right">
                  <select
                    id="shorty"
                    class="form-control"
                    v-model="sort_by"
                    @change="applyFilter"
                  >
                    <option value="default" selected>Sort: Default</option>
                    <option value="low">Lowest Price</option>
                    <option value="high">Highest Price</option>
                    <option value="views">Most Views</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Single Property Start -->
          <div
            class="col-lg-12 col-md-12"
            v-for="(property, i) in properties"
            :key="`property-${i}`"
          >
            <div class="property-listing property-1">
              <div class="listing-img-wrapper">
                <router-link
                  :to="{ name: 'page', params: { slug: property.slug } }"
                >
                  <span v-if="property.featured" class="is-featured"
                    >Promoted</span
                  >
                  <img
                    :src="property.image"
                    class="img-fluid mx-auto"
                    :alt="agency.name"
                  />
                </router-link>
              </div>

              <div class="listing-content">
                <div class="listing-detail-wrapper-box">
                  <div class="listing-detail-wrapper">
                    <div class="listing-short-detail">
                      <h4 class="listing-name">
                        <router-link
                          :to="{
                            name: 'page',
                            params: { slug: property.slug },
                          }"
                          >{{ agency.name }}</router-link
                        >
                      </h4>
                      <span class="prt-types sale mt-0"
                        >For {{ agency.name }}</span
                      >
                      <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span>
                    </div>
                  </div>
                  <div class="listing-detail-wrapper py-0">
                    <div class="list-price">
                      <h6 class="listing-card-info-price">
                        <small>KES</small> {{ property.amount }}
                      </h6>
                    </div>
                  </div>
                </div>

                <div class="price-features-wrapper">
                  <div class="list-fx-features my-0">
                    <div
                      class="listing-card-info-icon"
                      v-for="(quality, j) in property.qualities"
                      :key="`quality-${(i + 1) * (j + 1)}`"
                    >
                      <div class="inc-fleat-icon">
                        <img :src="quality.icon" width="13" alt="" />
                      </div>
                      {{ quality.name }}
                    </div>
                  </div>
                </div>

                <div class="listing-footer-wrapper">
                  <div class="listing-locate">
                    <span class="listing-location"
                      ><i class="ti-location-pin"></i
                      >{{ property.region }}</span
                    >
                  </div>
                  <div class="listing-detail-btn">
                    <router-link
                      :to="{
                        name: 'page',
                        params: { slug: property.slug },
                      }"
                      class="more-btn"
                      >View</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="properties.length == 0">
            This agency has not added any properties yet.
          </div>
          <!-- Single Property End -->
        </div>

        <!-- Pagination -->
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <pagination
              v-model="pagination.page"
              :records="pagination.total"
              :perPage="pagination.per_page"
              @paginate="$router.push({ query: { page: pagination.page }})"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort_by: "default",
      message: {}
    };
  },

  props: ["properties", "pagination", "agency"],

  methods: {
    openFilterSearch() {
      document.getElementById("filter_search").style.display = "block";
    },

    closeFilterSearch() {
      document.getElementById("filter_search").style.display = "none";
    },

    applyFilter() {
      let query = {};

      if (this.sort_by != "default") {
        query.sort = this.sort_by;
      }

      this.$router.push({ query: query });
    },
  },
};
</script>
