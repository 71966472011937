<template>
  <div>
    <section
      class="image-cover"
      style="background: url(/assets/img/banner-2.jpg) no-repeat"
      data-overlay="5"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-8">
            <div class="caption-wrap-content text-center">
              <h2>Search For The Perfect Place in your City</h2>
              <p class="mb-5">
                Or move to another town/city. Check out the available places.
              </p>
              <div
                class="full-search-2 eclip-search italian-search hero-search-radius shadow-hard mt-5"
              >
                <form class="hero-search-content" @submit.prevent="applyFilter">
                  <div class="row">
                    <div class="col-lg-9 col-md-9 col-sm-12 elio">
                      <div class="form-group">
                        <div class="input-with-icon">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            v-model="search_term"
                          />
                          <img src="/assets/img/pin.svg" width="20" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12">
                      <div class="form-group">
                        <button class="btn search-btn btn-theme">
                          <i class="lni lni-search mr-2"></i> Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container pt-4">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="filter_search_opt">
            <a href="#" @click.prevent="openFilterSearch"
              >Filter Properties<i class="ml-2 ti-menu"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- property Sidebar -->
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div
            class="simple-sidebar sm-sidebar"
            id="filter_search"
            style="left: 0"
          >
            <div class="search-sidebar_header">
              <h4 class="ssh_heading">Close Filter</h4>
              <button
                @click.prevent="closeFilterSearch"
                class="w3-bar-item w3-button w3-large"
              >
                <i class="lni lni-close"></i>
              </button>
            </div>

            <!-- Find New Property -->
            <div class="sidebar-widgets">
              <div class="search-inner p-0">
                <h3>Filter</h3>

                <div class="filter_wraps">
                  <!-- Single Search -->
                  <div class="single_search_boxed">
                    <div class="widget-boxed-header">
                      <h4>
                        <a
                          href="#where"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          role="button"
                          class="collapsed"
                          >Where<span class="selected">{{
                            filter_region.name
                          }}</span></a
                        >
                      </h4>
                    </div>
                    <div
                      class="widget-boxed-body collapse"
                      id="where"
                      data-parent="#where"
                    >
                      <input
                        type="text"
                        placeholder="Search County"
                        class="filter-region mb-2"
                        v-model="search_region"
                      />
                      <div class="side-list no-border custom-filter scrollbar">
                        <!-- Single Filter Card -->
                        <div class="single_filter_card">
                          <div class="card-body pt-0">
                            <div class="inner_widget_link">
                              <ul class="no-ul-list filter-list">
                                <li v-if="search_region.length == 0">
                                  <input
                                    id="anywhere"
                                    class="radio-custom"
                                    name="where"
                                    type="radio"
                                    :value="{ name: 'anywhere', slug: 0 }"
                                    v-model="filter_region"
                                  />
                                  <label
                                    for="anywhere"
                                    class="radio-custom-label"
                                    >Anywhere</label
                                  >
                                </li>
                                <li
                                  v-for="(region, i) in filtered_regions"
                                  :key="`regio-${i}`"
                                >
                                  <input
                                    :id="`region-${i}`"
                                    class="radio-custom"
                                    name="where"
                                    type="radio"
                                    :value="region"
                                    v-model="filter_region"
                                  />
                                  <label
                                    :for="`region-${i}`"
                                    class="radio-custom-label"
                                    >{{ region.name }}</label
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Single Search -->
                  <div class="single_search_boxed">
                    <div class="widget-boxed-header">
                      <h4>
                        <a
                          href="#fptype"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          role="button"
                          class="collapsed"
                          >Property Type<span class="selected">{{
                            filter_type == "buy"
                              ? "For Sale"
                              : filter_type == "all"
                              ? "All"
                              : "For Rent"
                          }}</span></a
                        >
                      </h4>
                    </div>
                    <div
                      class="widget-boxed-body collapse"
                      id="fptype"
                      data-parent="#fptype"
                    >
                      <div class="side-list no-border">
                        <!-- Single Filter Card -->
                        <div class="single_filter_card">
                          <div class="card-body pt-0">
                            <div class="inner_widget_link">
                              <ul class="no-ul-list filter-list">
                                <li>
                                  <input
                                    id="type-all"
                                    class="radio-custom"
                                    name="ptype"
                                    type="radio"
                                    value="all"
                                    v-model="filter_type"
                                  />
                                  <label
                                    for="type-all"
                                    class="radio-custom-label"
                                    >All</label
                                  >
                                </li>
                                <li>
                                  <input
                                    id="type-buy"
                                    class="radio-custom"
                                    name="ptype"
                                    type="radio"
                                    value="buy"
                                    v-model="filter_type"
                                  />
                                  <label
                                    for="type-buy"
                                    class="radio-custom-label"
                                    >For Sale</label
                                  >
                                </li>
                                <li>
                                  <input
                                    id="type-rent"
                                    class="radio-custom"
                                    name="ptype"
                                    type="radio"
                                    value="rent"
                                    v-model="filter_type"
                                  />
                                  <label
                                    for="type-rent"
                                    class="radio-custom-label"
                                    >For Rent</label
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Single Search -->
                  <div class="single_search_boxed">
                    <div class="widget-boxed-header">
                      <h4>
                        <a
                          href="#fcategories"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          role="button"
                          class="collapsed"
                          >Property Category<span class="selected">{{
                            filter_category.name
                          }}</span></a
                        >
                      </h4>
                    </div>
                    <div
                      class="widget-boxed-body collapse"
                      id="fcategories"
                      data-parent="#fcategories"
                    >
                      <input
                        type="text"
                        placeholder="Search Category"
                        class="filter-region mb-2"
                        v-model="search_category"
                      />
                      <div class="side-list no-border custom-filter scrollbar">
                        <!-- Single Filter Card -->
                        <div class="single_filter_card">
                          <div class="card-body pt-0">
                            <div class="inner_widget_link">
                              <ul class="no-ul-list filter-list">
                                <li v-if="search_category.length == 0">
                                  <input
                                    id="all-categories"
                                    class="radio-custom"
                                    name="bed"
                                    type="radio"
                                    :value="{ name: 'all', slug: 0 }"
                                    v-model="filter_category"
                                  />
                                  <label
                                    for="all-categories"
                                    class="radio-custom-label"
                                    >All Categories</label
                                  >
                                </li>
                                <li
                                  v-for="(category, i) in filtered_categories"
                                  :key="`category-${i}`"
                                >
                                  <input
                                    :id="`category-${i}`"
                                    class="radio-custom"
                                    name="bed"
                                    type="radio"
                                    :value="category"
                                    v-model="filter_category"
                                  />
                                  <label
                                    :for="`category-${i}`"
                                    class="radio-custom-label"
                                    >{{ category.name }}</label
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Single Search -->
                  <div class="single_search_boxed">
                    <div class="widget-boxed-header">
                      <h4>
                        <a
                          href="#fbedrooms"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          role="button"
                          class="collapsed"
                          >Bedrooms<span class="selected"
                            >{{ filter_bedrooms }}
                            {{ filter_bedrooms == "All" ? "" : "Bedroom"
                            }}{{
                              filter_bedrooms == 1 || filter_bedrooms == "All"
                                ? ""
                                : "s"
                            }}</span
                          ></a
                        >
                      </h4>
                    </div>
                    <div
                      class="widget-boxed-body collapse"
                      id="fbedrooms"
                      data-parent="#fbedrooms"
                    >
                      <div class="side-list no-border">
                        <!-- Single Filter Card -->
                        <div class="single_filter_card">
                          <div class="card-body pt-0">
                            <div class="inner_widget_link">
                              <ul class="no-ul-list filter-list">
                                <li>
                                  <input
                                    id="all-bedrooms"
                                    class="radio-custom"
                                    name="bed"
                                    type="radio"
                                    value="All"
                                    v-model="filter_bedrooms"
                                  />
                                  <label
                                    for="all-bedrooms"
                                    class="radio-custom-label"
                                    >All</label
                                  >
                                </li>
                                <li v-for="i in 5" :key="`bedroom-${i}`">
                                  <input
                                    :id="`bedroom-${i}`"
                                    class="radio-custom"
                                    name="bed"
                                    type="radio"
                                    :value="i"
                                    v-model="filter_bedrooms"
                                  />
                                  <label
                                    :for="`bedroom-${i}`"
                                    class="radio-custom-label"
                                    >{{ i }}{{ i == 5 ? "+" : "" }} Bedroom{{
                                      i == 1 ? "" : "s"
                                    }}</label
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Single Search -->
                  <div class="single_search_boxed">
                    <div class="widget-boxed-header">
                      <h4>
                        <a
                          href="#fprice"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          role="button"
                          class="collapsed"
                          >Price Range<span class="selected"
                            ><small v-if="filter_price != 'any'">KES</small>
                            {{
                              filter_price == "any"
                                ? "Any"
                                : `${filter_price.min}${
                                    filter_price.max == "+"
                                      ? "+"
                                      : " - " + filter_price.max
                                  }`
                            }}</span
                          ></a
                        >
                      </h4>
                    </div>
                    <div
                      class="widget-boxed-body collapse custom-filter scrollbar"
                      id="fprice"
                      data-parent="#fprice"
                    >
                      <div class="side-list no-border">
                        <!-- Single Filter Card -->
                        <div class="single_filter_card">
                          <div class="card-body pt-0">
                            <div class="inner_widget_link">
                              <ul class="no-ul-list filter-list">
                                <li>
                                  <input
                                    id="any-price"
                                    class="radio-custom"
                                    name="prices"
                                    type="radio"
                                    value="any"
                                    v-model="filter_price"
                                  />
                                  <label
                                    for="any-price"
                                    class="radio-custom-label"
                                    >Any</label
                                  >
                                </li>
                                <li
                                  v-for="(range, i) in price_ranges"
                                  :key="`price-range-${i}`"
                                >
                                  <input
                                    :id="`range-${i}`"
                                    class="radio-custom"
                                    name="prices"
                                    type="radio"
                                    :value="range"
                                    v-model="filter_price"
                                  />
                                  <label
                                    :for="`range-${i}`"
                                    class="radio-custom-label"
                                    ><small>KES</small> {{ range.min
                                    }}{{
                                      range.max == "+" ? "+" : ` - ${range.max}`
                                    }}</label
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Single Search -->
                  <div class="single_search_boxed">
                    <div class="widget-boxed-header">
                      <h4>
                        <a
                          href="#ameneties"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          role="button"
                          class="collapsed"
                          >Ameneties<span class="selected"
                            >{{ filter_amenities.length }} Selected</span
                          ></a
                        >
                      </h4>
                    </div>
                    <div
                      class="widget-boxed-body collapse custom-filter scrollbar"
                      id="ameneties"
                      data-parent="#ameneties"
                    >
                      <div class="side-list no-border">
                        <!-- Single Filter Card -->
                        <div class="single_filter_card">
                          <div class="card-body pt-0">
                            <div class="inner_widget_link">
                              <ul class="no-ul-list filter-list">
                                <li
                                  v-for="(amenity, i) in amenities"
                                  :key="`amenity-${i}`"
                                >
                                  <input
                                    :id="`amenity-${i}`"
                                    class="checkbox-custom"
                                    name="ADA"
                                    type="checkbox"
                                    :value="amenity"
                                    v-model="filter_amenities"
                                  />
                                  <label
                                    :for="`amenity-${i}`"
                                    class="checkbox-custom-label"
                                    >{{ amenity.name }}</label
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group filter_button">
                  <button
                    type="submit"
                    class="btn btn btn-theme-light-2 rounded full-width"
                    @click.prevent="applyFilter"
                  >
                    <i class="lni lni-search mr-2"></i> Apply Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Sidebar End -->
        </div>

        <div class="col-lg-8 col-md-12 list-layout">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
              <div class="item-shorting-box">
                <div class="item-shorting clearfix">
                  <div class="left-column pull-left">
                    <h4 class="m-0">
                      Showing
                      {{
                        pagination.total > 0
                          ? (pagination.page - 1) * 10 + 1
                          : 0
                      }}-{{
                        (pagination.page - 1) * 10 + 10 >= pagination.total
                          ? pagination.total
                          : (pagination.page - 1) * 10 + 10
                      }}
                      of {{ pagination.total }} Results
                    </h4>
                  </div>
                </div>
                <div
                  class="item-shorting-box-right"
                  v-if="pagination.total > 1"
                >
                  <div class="shorting-by text-right">
                    <select
                      id="shorty"
                      class="form-control"
                      v-model="sort_by"
                      @change="applyFilter"
                    >
                      <option value="default">Sort: Default</option>
                      <option value="low">Lowest Price</option>
                      <option value="high">Highest Price</option>
                      <option value="views">Most Views</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Single Property Start -->
            <div
              class="col-lg-12 col-md-12"
              v-for="(property, i) in properties"
              :key="`property-${i}`"
            >
              <div class="property-listing property-1">
                <div class="listing-img-wrapper">
                  <router-link
                    :to="{ name: 'page', params: { slug: property.slug } }"
                  >
                    <span v-if="property.featured" class="is-featured"
                      >Promoted</span
                    >
                    <img
                      :src="property.image"
                      class="img-fluid mx-auto"
                      :alt="property.name"
                    />
                  </router-link>
                </div>

                <div class="listing-content">
                  <div class="listing-detail-wrapper-box">
                    <div class="listing-detail-wrapper">
                      <div class="listing-short-detail">
                        <h4 class="listing-name">
                          <router-link
                            :to="{
                              name: 'page',
                              params: { slug: property.slug },
                            }"
                            >{{ property.name }}</router-link
                          >
                        </h4>
                        <span class="prt-types sale mt-0">For {{ property.type }}</span>
                        <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
                      </div>
                    </div>
                    <div class="listing-detail-wrapper py-0">
                      <div class="list-price">
                        <h6 class="listing-card-info-price">
                          <small>KES</small> {{ property.amount }}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div class="price-features-wrapper">
                    <div class="list-fx-features my-0">
                      <div
                        class="listing-card-info-icon"
                        v-for="(quality, j) in property.qualities"
                        :key="`quality-${(i + 1) * (j + 1)}`"
                      >
                        <div class="inc-fleat-icon">
                          <img :src="quality.icon" width="13" alt="" />
                        </div>
                        {{ quality.name }}
                      </div>
                    </div>
                  </div>

                  <div class="listing-footer-wrapper">
                    <div class="listing-locate">
                      <span class="listing-location"
                        ><i class="ti-location-pin"></i
                        >{{ property.region }}</span
                      >
                    </div>
                    <div class="listing-detail-btn">
                      <router-link
                        :to="{
                          name: 'page',
                          params: { slug: property.slug },
                        }"
                        class="more-btn"
                        >View</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Single Property End -->
          </div>

          <!-- Pagination -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <pagination
                v-model="pagination.page"
                :records="pagination.total"
                :perPage="pagination.per_page"
                @paginate="$router.push({ query: { page: pagination.page }})"
              />
            </div>
          </div>

          <template v-if="pagination.total < 5">
            <more-properties :total="pagination.total" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreProperties from "./components/MoreProperties.vue";

export default {
  components: {
    MoreProperties,
  },

  mounted() {
    if (this.$route.query.search) {
      this.search_term = this.$route.query.search;
    }

    if (this.$route.query.region) {
      this.filter_region = this.regions.filter((region) => {
        return region.slug == this.$route.query.region;
      })[0];
    }

    if (this.$route.query.category) {
      this.filter_category = this.categories.filter((category) => {
        return category.slug == this.$route.query.category;
      })[0];
    }

    if (this.$route.query.type) {
      this.filter_type = this.$route.query.type;
    }

    if (this.$route.query.bedrooms) {
      this.filter_bedrooms = this.$route.query.bedrooms;
    }

    if (this.$route.query.sort) {
      this.sort_by = this.$route.query.sort;
    }

    if (this.$route.query.min_price) {
      this.filter_price = this.price_ranges.filter((range) => {
        return (range.min = this.$route.query.min_price);
      })[0];
    }

    if (this.$route.query.amenities) {
      let amenities = this.$route.query.amenities.split(",").map((id) => {
        return parseInt(id);
      });

      this.filter_amenities = this.amenities.filter((amenity) => {
        return amenities.indexOf(amenity.id) >= 0;
      });
    }
  },

  data() {
    return {
      search_term: "",
      filter_region: { name: "anywhere", slug: 0 },
      filter_type: "all",
      filter_category: { name: "all", slug: 0 },
      filter_bedrooms: "All",
      filter_price: "any",
      price_ranges: [
        { min: 0, max: "10,000" },
        { min: "10,001", max: "20,000" },
        { min: "20,001", max: "50,000" },
        { min: "50,001", max: "100,000" },
        { min: "100,001", max: "250,000" },
        { min: "250,001", max: "500,000" },
        { min: "500,001", max: "1,000,000" },
        { min: "1,000,001", max: "2,500,000" },
        { min: "2,500,001", max: "5,000,000" },
        { min: "5,000,001", max: "10,000,000" },
        { min: "10,000,000", max: "+" },
      ],
      filter_amenities: [],
      search_region: "",
      search_category: "",
      sort_by: "default",
    };
  },

  props: ["properties", "pagination", "regions", "categories", "amenities"],

  methods: {
    openFilterSearch() {
      document.getElementById("filter_search").style.display = "block";
    },

    closeFilterSearch() {
      document.getElementById("filter_search").style.display = "none";
    },

    applyFilter() {
      let query = {};

      if (this.search_term) {
        query.search = this.search_term;
      }

      if (this.filter_region.slug != 0) {
        query.region = this.filter_region.slug;
      }

      if (this.filter_type != "all") {
        query.type = this.filter_type;
      }

      if (this.filter_category.slug != 0) {
        query.category = this.filter_category.slug;
      }

      if (this.filter_bedrooms != "All") {
        query.bedrooms = this.filter_bedrooms;
      }

      if (this.filter_price != "any") {
        query.min_price = this.filter_price.min;

        if (this.filter_price.max != "+") {
          query.max_price = this.filter_price.max;
        }
      }

      if (this.sort_by != "default") {
        query.sort = this.sort_by;
      }

      if (this.filter_amenities.length > 0) {
        let amenities = this.filter_amenities
          .map((amenity) => {
            return amenity.id;
          })
          .join(",");

        query.amenities = amenities;
      }
      this.$router.push({ query: query });
    },
  },

  computed: {
    filtered_regions() {
      return this.regions.filter((region) => {
        return region.name.toLowerCase().indexOf(this.search_region) >= 0;
      });
    },

    filtered_categories() {
      return this.categories.filter((category) => {
        return category.name.toLowerCase().indexOf(this.search_category) >= 0;
      });
    },
  },
};
</script>
