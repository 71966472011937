<template>
  <div>
    <div class="alert alert-warning mb-0" v-if="agency.status == 'pending'">
      This agency has not been approved yet, only its agents can view this page.
    </div>
    <!-- ============================ Page Title Start================================== -->
    <div class="image-cover page-title bg-primary">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h2 class="ipt-title">Agency Details</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Agency Name Start================================== -->
    <section class="agent-page p-0 gray-simple">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="agency agency-list overlio-40">
              <div class="agency-avatar">
                <img :src="agency.logo" :alt="agency.name" />
              </div>

              <div class="agency-content">
                <div class="agency-name">
                  <h4>
                    <a href="#">{{ agency.name }}</a>
                  </h4>
                  <span
                    ><i class="lni lni-map-marker"></i
                    >{{ agency.address }}</span
                  >
                </div>

                <div class="agency-desc">
                  <p>{{ agency.about }}</p>
                </div>

                <div class="prt-detio">
                  <span>{{ agency.properties }} Properties</span>
                </div>

                <ul class="social-presence-icons mt-3">
                  <li
                    v-for="(val, type) in agency.socials"
                    :key="`social-${type}`"
                  >
                    <a :class="`${type}`" :href="val" :title="type" v-if="val"
                      ><i :class="`lni lni-${type}`"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Agency Name ================================== -->

    <!-- ============================ About Agency ================================== -->
    <section class="gray pt-5">
      <div class="container">
        <div class="row">
          <!-- property main detail -->
          <div class="col-lg-8 col-md-12 col-sm-12">
            <!-- Single Block Wrap -->
            <div class="block-wrap">
              <div class="block-header ags py-3">
                <h4 class="block-title">Agency Info</h4>
              </div>

              <div class="block-body">
                <ul class="dw-proprty-info" v-if="show_contacts">
                  <li>
                    <strong>Email</strong
                    ><a
                      href="#"
                      v-if="contacts.email == null"
                      data-bs-toggle="modal"
                      data-bs-target="#getEmail"
                      >Get Email</a
                    >
                    <a href="#" v-if="contacts.email != null">{{
                      contacts.email
                    }}</a>
                  </li>
                  <li>
                    <strong>Phone</strong
                    ><a
                      href="#"
                      v-if="contacts.phone_number == null"
                      data-bs-toggle="modal"
                      data-bs-target="#getPhoneNumber"
                      >Get Phone Number</a
                    >
                    <a href="#" v-if="contacts.phone_number != null">{{
                      contacts.phone_number
                    }}</a>
                  </li>
                  <li>
                    <strong>Whatsapp</strong
                    ><a
                      href="#"
                      v-if="contacts.whatsapp == null"
                      data-bs-toggle="modal"
                      data-bs-target="#getWhatsapp"
                      >Get Whatsapp</a
                    >
                    <a href="#" v-if="contacts.whatsapp != null">{{
                      contacts.whatsapp
                    }}</a>
                  </li>
                  <li><strong>Address</strong>{{ agency.address }}</li>
                </ul>
              </div>
            </div>

            <!-- Single Block Wrap -->
            <div class="block-wraps">
              <div class="block-wraps-header">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="property-tab"
                      data-bs-toggle="tab"
                      href="#property"
                      role="tab"
                      aria-controls="property"
                      aria-selected="true"
                      >Properties</a
                    >
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="agents-tab"
                      data-bs-toggle="tab"
                      href="#agent"
                      role="tab"
                      aria-controls="agents"
                      aria-selected="true"
                      >Authorized Agents</a
                    >
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="property"
                    role="tabpanel"
                    aria-labelledby="property-tab"
                  >
                    <!-- row -->
                    <div class="row">
                      <!-- Single Property -->
                      <div class="col-md-12" v-if="properties.length == 0">
                        There are no properties to display.
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12"
                        v-for="(property, i) in properties"
                        :key="`property-${i}`"
                      >
                        <div class="property-listing property-2">
                          <div class="listing-img-wrapper">
                            <div class="list-img-slide">
                              <div class="clicks">
                                <div>
                                  <router-link
                                    :to="{
                                      name: 'page',
                                      params: { slug: property.slug },
                                    }"
                                  >
                                    <img
                                      :src="property.image"
                                      class="img-fluid mx-auto"
                                      alt=""
                                    />
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="listing-detail-wrapper py-0">
                            <div class="listing-short-detail-wrap">
                              <div class="listing-short-detail">
                                <span class="property-type"
                                  >For {{ property.type }}</span
                                >
                                <h4 class="listing-name verified">
                                  <router-link
                                    :to="{
                                      name: 'page',
                                      params: { slug: property.slug },
                                    }"
                                    class="prt-link-detail"
                                    >{{ property.name }}</router-link
                                  >
                                </h4>
                                <h6 class="listing-card-info-price p-0 mt-2">
                                  <sub>KES</sub> {{ property.amount }}
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div class="price-features-wrapper">
                            <div class="list-fx-features mt-0">
                              <div
                                class="listing-card-info-icon"
                                v-for="(quality, i) in property.qualities"
                                :key="`qualitity-${i}`"
                              >
                                <div class="inc-fleat-icon">
                                  <img
                                    :src="quality.icon"
                                    width="13"
                                    :alt="quality.name"
                                  />
                                </div>
                                {{ quality.name }}
                              </div>
                            </div>
                          </div>

                          <div class="listing-detail-footer mt-0">
                            <div class="footer-first">
                              <div class="foot-location">
                                <img
                                  src="/assets/img/pin.svg"
                                  width="18"
                                  alt=""
                                />{{ property.region }}
                              </div>
                            </div>
                            <div class="footer-flex">
                              <router-link
                                :to="{
                                  name: 'page',
                                  params: { slug: property.slug },
                                }"
                                class="prt-view"
                                >View</router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Single Property -->
                    </div>
                    <!-- // row -->

                    <div class="row">
                      <div
                        class="col-lg-12 col-md-12 col-sm-12 text-center"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="agent"
                    role="tabpanel"
                    aria-labelledby="agent-tab"
                  >
                    <div class="row">
                      <!-- Single Agent -->
                      <div
                        class="col-md-4"
                        v-for="(agent, i) in agents"
                        :key="`agent-${i}`"
                      >
                        <div class="card shadow">
                          <div class="card-body text-center">
                            <img
                              :src="agent.avatar"
                              :alt="agent.name"
                              height="80"
                              class="rounded mb-3"
                            />
                            <h5>{{ agent.name }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <a
                href="#"
                class="btn btn-primary float-end btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#add-review"
                v-if="$auth.hasToken()"
                >Leave A Review</a
              >
              <h4>Reviews</h4>
              <template v-if="!reviews_loading">
                <table class="table bg-white">
                <tbody>
                  <tr v-for="(r, i) in reviews" :key="`review-${i}`">
                    <td width="20%">
                      <img :src="r.avatar" :alt="r.name" height="50px" />
                    </td>
                    <td>
                      <h6 class="mb-0">{{ r.name }}</h6>
                      <rating rating="4" />
                      <p style="line-height: 1.3">{{ r.content }}</p>
                    </td>
                  </tr>
                  <tr v-if="reviews.length == 0">
                    <td colspan="2">There are no reviews yet.</td>
                  </tr>
                </tbody>
              </table>
              <div class="row" v-if="reviews.length > 0">
                <div class="col-md-12">
                  <pagination
                    v-model="reviews_pagination.page"
                    :records="reviews_pagination.records"
                    @paginate="fetch"
                    :perPage="5"
                  />
                </div>
              </div>
              </template>
              <loading v-if="reviews_loading" />
            </div>
          </div>

          <!-- property Sidebar -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="details-sidebar">
              <!-- Agent Detail -->
              <div class="sides-widget">
                <div class="sides-widget-header">
                  <div class="agent-photo">
                    <img :src="agency.logo" :alt="agency.name" />
                  </div>
                  <div class="sides-widget-details">
                    <h4>
                      <a href="#">{{ agency.name }}</a>
                    </h4>
                    <span
                      ><i class="lni-phone-handset"></i
                      >{{ agency.phone_number }}</span
                    >
                  </div>
                  <div class="clearfix"></div>
                </div>

                <form @submit.prevent="sendMessage">
                  <div class="sides-widget-body simple-form">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Email"
                        v-model="message.email"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Phone No.</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Phone"
                        v-model="message.phone_number"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea
                        class="form-control"
                        required
                        v-model="message.message"
                      >
I'm interested in this property.</textarea
                      >
                    </div>
                    <button class="btn btn-black btn-md rounded full-width">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>

              <!-- Featured Property -->
              <div class="sidebar-widgets">
                <h4>More Featured Properties</h4>

                <div class="sidebar_featured_property">
                  <!-- List Sibar Property -->
                  <div
                    class="sides_list_property"
                    v-for="(property, i) in featured"
                    :key="`featured-${i}`"
                  >
                    <div class="sides_list_property_thumb">
                      <router-link :to="{ name: 'page', params: { slug: property.slug }}">
                        <img
                          :src="property.image"
                          class="img-fluid"
                          :alt="property.name"
                        />
                      </router-link>
                    </div>
                    <div class="sides_list_property_detail">
                      <h4>
                        <router-link :to="{ name: 'page', params: { slug: property.slug }}">{{ property.name }}</router-link>
                      </h4>
                      <span
                        ><i class="ti-location-pin"></i
                        >{{ property.region }}</span
                      >
                      <div class="lists_property_price">
                        <div class="lists_property_types">
                          <div class="property_types_vlix sale">
                            For {{ property.type }}
                          </div>
                        </div>
                        <div class="lists_property_price_value">
                          <h4><sub>KES</sub> {{ property.amount }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ About Agency End ================================== -->

    <div
      class="modal fade"
      id="getPhoneNumber"
      tabindex="-1"
      aria-labelledby="getPhoneNumberLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="getPhoneNumberLabel">
              Get Phone Number
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="py-3 px-4">
            <alert type="info"
              >This information is shared with the property owner to enable
              easier communication. Read our
              <privacy-policy-url /></alert
            >
            <form @submit.prevent="getContacts('phone_number')">
              <div class="form-group">
                <label>Your Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  v-model="user.name"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label>Your Phone Number</label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  v-model="user.phone_number"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary" data-bs-dismiss="modal">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="getWhatsapp"
      tabindex="-1"
      aria-labelledby="getWhatsappLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="getWhatsappLabel">
              Get Whatsapp Number
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="py-3 px-4">
            <alert type="info"
              >This information is shared with the property owner to enable
              easier communication. Read our
              <privacy-policy-url /></alert
            >
            <form @submit.prevent="getContacts('whatsapp')">
              <div class="form-group">
                <label>Your Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  v-model="user.name"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label>Your Phone Number</label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  v-model="user.whatsapp"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary" data-bs-dismiss="modal">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="getEmail"
      tabindex="-1"
      aria-labelledby="getEmailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="getEmailLabel">Get Email</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="py-3 px-4">
            <alert type="info"
              >This information is shared with the property owner to enable
              easier communication. Read our
              <privacy-policy-url /></alert
            >
            <form @submit.prevent="getContacts('email')">
              <div class="form-group">
                <label>Your Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  v-model="user.name"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label>Your Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  v-model="user.email"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary" data-bs-dismiss="modal">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="add-review"
      tabindex="-1"
      aria-labelledby="add-review-label"
      aria-hidden="true"
      v-if="$auth.hasToken()"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="add-review-label">Leave A Review</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="py-3 px-4">
            <form @submit.prevent="submitReview">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Your Name</label
                    ><input
                      type="text"
                      placeholder="Full Name"
                      required="required"
                      class="form-control"
                      v-model="review.name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Your Email</label
                    ><input
                      type="text"
                      placeholder="Full Email"
                      required="required"
                      class="form-control"
                      v-model="review.email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Your Phone Number</label
                    ><input
                      type="text"
                      placeholder="Full Phone Number"
                      required="required"
                      class="form-control"
                      v-model="review.phone_number"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Rate Your Experience</label>
                    <input-rating class="py-3" v-model="review.rating" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Your Comments</label
                    ><textarea
                      rows="6"
                      placeholder="Your Comments"
                      required="required"
                      class="form-control"
                      v-model="review.content"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <button class="btn btn-primary">
                      <i class="lni lni-save"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="close-add-review-modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.review = {
      name: this.auth_user.name,
      email: this.auth_user.email,
      phone_number: this.auth_user.phone_number,
    };

    this.fetch();
  },

  data() {
    return {
      message: {},
      user: {},
      show_contacts: true,
      contacts: { phone_number: null, email: null, whatsapp: null },
      review: {},
      reviews: [],
      reviews_loading: true,
      reviews_pagination: { page: 1, records: 1 },
    };
  },

  props: ["properties", "pagination", "agency", "featured", "agents"],

  methods: {
    fetch() {
      this.reviews_loading = true;

      this.$axios
        .get(`/api/v1/reviews/agency?id=${this.agency.id}&page=${this.reviews_pagination.page}`)
        .then((response) => {
          this.reviews_loading = false;
          this.reviews = response.data.reviews;
        });
    },

    submitReview() {
      let data = this.review
      data.foreign_id = this.agency.id
      data.type = 'agency'

      this.$axios
        .post(`/api/v1/reviews/agency`, data)
        .then(() => {
          this.fetch()
          this.$swal('success', 'Your review has been submitted.', 'Success')
          document.getElementById('close-add-review-modal').click()
        });
    },

    applyFilter() {
      let query = {};

      if (this.sort_by != "default") {
        query.sort = this.sort_by;
      }

      this.$router.push({ query: query });
    },

    sendMessage() {
      this.$axios
        .post(
          `/api/v1/agencies/${this.$route.params.slug}/send-message`,
          this.message
        )
        .then(() => {
          this.message = {};
        });
    },

    getContacts(type) {
      let data = {
        type: type,
        name: this.user.name,
        content: this.user[type],
      };

      this.$axios
        .post(`/api/v1/contacts/${this.agency.id}`, data)
        .then((response) => {
          this.show_contacts = false;
          this.contacts[type] = response.data[type];
          this.show_contacts = true;
        });
    },
  },

  computed: {
    auth_user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
