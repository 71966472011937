<template>
  <div class="row mt-4">
    <h4 v-if="properties.length > 0">Similar Properties</h4>
    <div
      class="col-lg-12 col-md-12"
      v-for="(property, i) in properties"
      :key="`property-${i}`"
    >
      <div class="property-listing property-1">
        <div class="listing-img-wrapper">
          <router-link :to="{ name: 'page', params: { slug: property.slug } }">
            <span v-if="property.featured" class="is-featured">Promoted</span>
            <img
              :src="property.image"
              class="img-fluid mx-auto"
              :alt="property.name"
            />
          </router-link>
        </div>

        <div class="listing-content">
          <div class="listing-detail-wrapper-box">
            <div class="listing-detail-wrapper">
              <div class="listing-short-detail">
                <h4 class="listing-name">
                  <router-link
                    :to="{
                      name: 'page',
                      params: { slug: property.slug },
                    }"
                    >{{ property.name }}</router-link
                  >
                </h4>
                <span class="prt-types sale mt-0">For {{ property.type }}</span>
                <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
              </div>
            </div>
            <div class="listing-detail-wrapper py-0">
              <div class="list-price">
                <h6 class="listing-card-info-price">
                  <small>KES</small> {{ property.amount }}
                </h6>
              </div>
            </div>
          </div>

          <div class="price-features-wrapper">
            <div class="list-fx-features my-0">
              <div
                class="listing-card-info-icon"
                v-for="(quality, j) in property.qualities"
                :key="`quality-${(i + 1) * (j + 1)}`"
              >
                <div class="inc-fleat-icon">
                  <img :src="quality.icon" width="13" alt="" />
                </div>
                {{ quality.name }}
              </div>
            </div>
          </div>

          <div class="listing-footer-wrapper">
            <div class="listing-locate">
              <span class="listing-location"
                ><i class="ti-location-pin"></i>{{ property.region }}</span
              >
            </div>
            <div class="listing-detail-btn">
              <router-link
                :to="{
                  name: 'page',
                  params: { slug: property.slug },
                }"
                class="more-btn"
                >View</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      properties: [],
    };
  },

  props: ["region", 'type', 'property_id'],

  methods: {
    fetch() {
      this.$axios.get(`/api/v1/properties?type=${this.type}&region=${this.region}&sort=random&ignore=${this.property_id}`).then((response) => {
        this.properties = response.data.properties;
      });
    },
  },
};
</script>
