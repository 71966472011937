<template>
  <div>
    <!-- ============================ Hero Banner  Start================================== -->
    <div class="container-fluid">
      <alert type="warning" v-if="property.status != 'active'">
        Only you can view this post as it is {{ property.status }}.
      </alert>
      <div class="featured_slick_gallery gray">
        <hooper :settings="hooperSettings">
          <slide
            v-for="(image, i) in property.images"
            :key="`image-${i}`"
            :index="`image-${i}`"
          >
            <a href="#" class="mfp-gallery" @click.prevent="openPopup(image)"
              ><img :src="image" class="img-fluid mx-auto" :alt="image" style="width: 100%; height: 100%"
            /></a>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
        <a href="JavaScript:Void(0);" id="view-photos" class="btn-view-pic top"
          >View photos</a
        >
      </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ============================ Property Header Info Start================================== -->
    <section class="gray-simple rtl p-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-md-12">
            <div class="property_block_wrap style-3">
              <div class="pbw-flex-1">
                <div class="pbw-flex-thumb p-2">
                  <img
                    :src="property.image"
                    class="img-fluid"
                    :alt="property.name"
                    width="160px"
                  />
                </div>
              </div>
              <div class="pbw-flex">
                <div class="prt-detail-title-desc">
                  <span class="prt-types sale">For {{ property.type }}</span>
                  <h3>{{ property.name }}</h3>
                  <!-- <h4 class="text-secondary">#{{ property.uniqid }}</h4> -->
                  <span
                    ><i class="lni lni-map-marker"></i>
                    {{ property.region }}</span
                  >
                  <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
                  <h3 class="prt-price-fix">
                    <small>KES</small>{{ property.amount }}
                  </h3>
                  <div class="list-fx-features">
                    <div
                      class="listing-card-info-icon"
                      v-for="(quality, i) in property.qualities"
                      :key="`quality-${i}`"
                    >
                      <div class="inc-fleat-icon">
                        <img
                          :src="quality.icon"
                          width="13"
                          :alt="quality.name"
                        />
                      </div>
                      {{ quality.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Property Header Info Start================================== -->

    <!-- ============================ Property Detail Start ================================== -->
    <section class="gray-simple min pt-0">
      <div class="container">
        <div class="row">
          <!-- property main detail -->
          <div class="col-lg-8 col-md-12 col-sm-12">
            <!-- Single Block Wrap -->
            <div class="property_block_wrap style-2">
              <div class="property_block_wrap_header">
                <a
                  data-bs-toggle="collapse"
                  data-parent="#features"
                  data-bs-target="#clOne"
                  aria-controls="clOne"
                  href="javascript:void(0);"
                  aria-expanded="false"
                  ><h4 class="property_block_title">Detail & Features</h4></a
                >
              </div>
              <div
                id="clOne"
                class="panel-collapse collapse show"
                aria-labelledby="clOne"
              >
                <div class="block-body">
                  <ul class="deatil_features">
                    <li v-if="property.bedrooms">
                      <strong>Bedrooms:</strong>
                      {{ property.bedrooms }}
                    </li>
                    <li
                      v-for="(detail, i) in property.details"
                      :key="`detail-${i}`"
                    >
                      <strong>{{ detail.key }}:</strong>
                      {{ detail.value }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Single Block Wrap -->
            <div class="property_block_wrap style-2">
              <div class="property_block_wrap_header">
                <a
                  data-bs-toggle="collapse"
                  data-parent="#dsrp"
                  data-bs-target="#clTwo"
                  aria-controls="clTwo"
                  href="javascript:void(0);"
                  aria-expanded="true"
                  ><h4 class="property_block_title">Description</h4></a
                >
              </div>
              <div id="clTwo" class="panel-collapse collapse show">
                <div class="block-body" v-html="property.description"></div>
              </div>
            </div>

            <!-- Single Block Wrap -->
            <div class="property_block_wrap style-2">
              <div class="property_block_wrap_header">
                <a
                  data-bs-toggle="collapse"
                  data-parent="#amen"
                  data-bs-target="#clThree"
                  aria-controls="clThree"
                  href="javascript:void(0);"
                  aria-expanded="true"
                  ><h4 class="property_block_title">Ameneties</h4></a
                >
              </div>

              <div id="clThree" class="panel-collapse collapse show">
                <div class="block-body">
                  <ul class="avl-features third color">
                    <li
                      v-for="(amenity, i) in property.amenities"
                      :key="`amenity-${i}`"
                    >
                      {{ amenity }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Single Block Wrap -->
            <div class="property_block_wrap style-2" v-if="property.video_url">
              <div class="property_block_wrap_header">
                <a
                  data-bs-toggle="collapse"
                  data-parent="#vid"
                  data-bs-target="#clFour"
                  aria-controls="clFour"
                  href="javascript:void(0);"
                  aria-expanded="true"
                  class="collapsed"
                  ><h4 class="property_block_title">Property video</h4></a
                >
              </div>

              <div id="clFour" class="panel-collapse collapse">
                <div class="block-body">
                  <div class="property_video">
                    <video height="340" controls :poster="property.image">
                      <source :src="property.video_url" type="video/mp4" />
                      <source :src="property.video_url" type="video/webm" />
                    </video>
                  </div>
                </div>
              </div>
            </div>

            <!-- Single Block Wrap -->
            <div class="property_block_wrap style-2" v-if="property.location">
              <div class="property_block_wrap_header">
                <a
                  data-bs-toggle="collapse"
                  data-parent="#loca"
                  data-bs-target="#clSix"
                  aria-controls="clSix"
                  href="javascript:void(0);"
                  aria-expanded="true"
                  class="collapsed"
                  ><h4 class="property_block_title">Location</h4></a
                >
              </div>

              <div id="clSix" class="panel-collapse collapse">
                <div class="block-body">
                  <div class="map-container">
                    <div
                      id="singleMap"
                      :data-latitude="property.location.lat"
                      :data-longitude="property.location.lng"
                      data-mapTitle="Property Location"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Single Block Wrap -->
            <div class="property_block_wrap style-2">
              <div class="property_block_wrap_header">
                <a
                  data-bs-toggle="collapse"
                  data-parent="#clSev"
                  data-bs-target="#clSev"
                  aria-controls="clOne"
                  href="javascript:void(0);"
                  aria-expanded="true"
                  class="collapsed"
                  ><h4 class="property_block_title">Gallery</h4></a
                >
              </div>

              <div id="clSev" class="panel-collapse collapse">
                <div class="block-body">
                  <ul class="list-gallery-inline">
                    <li
                      v-for="(image, i) in property.images"
                      :key="`gallery-${i}`"
                    >
                      <a :href="image" class="mfp-gallery"
                        ><img
                          :src="image"
                          class="img-fluid mx-auto"
                          :alt="property.name"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- property Sidebar -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <!-- Like And Share -->
            <div class="like_share_wrap b-0" v-if="show_contacts">
              <div class="mb-4">
                <div class="d-grid mb-2">
                  <a
                    href="#"
                    data-bs-toggle="modal" 
                    data-bs-target="#getPhoneNumber"
                    class="btn btn-facebook rounded"
                    v-if="contacts.phone_number == null"
                  >
                    <i class="lni lni-phone mr-2"></i>
                    <span>Get Phone Number</span>
                  </a>
                  <a
                    :href="`tel:${contacts.phone_number}`"
                    class="btn btn-facebook rounded"
                    v-if="contacts.phone_number != null"
                  >
                    <i class="lni lni-phone mr-2"></i>
                    <span>{{ contacts.phone_number }}</span>
                  </a>
                </div>
                <div class="d-grid mb-2" v-if="property.contacts.has_whatsapp">
                  <a
                    href="#"
                    data-bs-toggle="modal" 
                    data-bs-target="#getWhatsapp"
                    class="btn btn-whatsapp rounded"
                    v-if="contacts.whatsapp == null"
                  >
                    <i class="lni lni-whatsapp mr-2"></i>
                    <span>Get Whatsapp Number</span>
                  </a>
                  <a
                    :href="`https://wa.me/${contacts.whatsapp}`"
                    target="_blank"
                    class="btn btn-whatsapp rounded"
                    v-if="contacts.whatsapp != null"
                  >
                    <i class="lni lni-whatsapp mr-2"></i>
                    <span>{{ contacts.whatsapp }}</span>
                  </a>
                </div>
                <div class="d-grid mb-2" v-if="property.contacts.has_email">
                  <a
                    href="#"
                    data-bs-toggle="modal" 
                    data-bs-target="#getEmail"
                    class="btn btn-google rounded"
                    v-if="contacts.email == null"
                  >
                    <i class="lni lni-envelope mr-2"></i>
                    <span>Get Email</span>
                  </a>
                  <a
                    :href="`mailto: ${contacts.email}`"
                    class="btn btn-google rounded"
                    v-if="contacts.email != null"
                  >
                    <i class="lni lni-envelope mr-2"></i>
                    <span>{{ contacts.email }}</span>
                  </a>
                </div>
              </div>
              <ul class="like_share_list">
                <li>
                  <a
                    href="JavaScript:Void(0);"
                    class="btn btn-likes"
                    data-bs-toggle="collapse"
                    data-parent="#social-sharing"
                    data-bs-target="#social-sharing"
                    aria-controls="socials"
                    ><i class="fa fa-share"></i>Share</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    @click.prevent="toggleBookmark"
                    class="btn btn-likes"
                    data-toggle="tooltip"
                    data-original-title="Save"
                    ><i class="fa fa-heart"></i>Save{{
                      property.bookmarked ? "d" : ""
                    }}</a
                  >
                </li>
              </ul>
              <div id="social-sharing" class="panel-collapse collapse">
                <ShareNetwork
                  network="facebook"
                  :url="`https://realhub.co.ke${$route.fullPath}`"
                  :title="property.name"
                  :description="property.description"
                  :quote="property.name"
                  :hashtags="`for${property.type}`"
                >
                  <i class="ti ti-facebook"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="twitter"
                  :url="`https://realhub.co.ke${$route.fullPath}`"
                  :title="property.name"
                  :description="property.description"
                  :quote="property.name"
                  :hashtags="`for${property.type}`"
                >
                  <i class="ti ti-twitter"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="email"
                  :url="`https://realhub.co.ke${$route.fullPath}`"
                  :title="property.name"
                  :description="property.description"
                  :quote="property.name"
                  :hashtags="`for${property.type}`"
                >
                  <i class="ti ti-email"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="whatsapp"
                  :url="`https://realhub.co.ke${$route.fullPath}`"
                  :title="property.name"
                  :description="property.description"
                  :quote="property.name"
                  :hashtags="`for${property.type}`"
                >
                  <i class="lni lni-whatsapp"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="sms"
                  :url="`https://realhub.co.ke${$route.fullPath}`"
                  :title="property.name"
                  :description="property.description"
                  :quote="property.name"
                  :hashtags="`for${property.type}`"
                >
                  <i class="lni lni-bubble"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="telegram"
                  :url="`https://realhub.co.ke${$route.fullPath}`"
                  :title="property.name"
                  :description="property.description"
                  :quote="property.name"
                  :hashtags="`for${property.type}`"
                >
                  <i class="lni lni-telegram"></i>
                </ShareNetwork>
              </div>
            </div>

            <div class="details-sidebar">
              <!-- Agent Detail -->
              <div class="sides-widget">
                <div class="sides-widget-header">
                  <div class="agent-photo">
                    <img :src="property.posted_by.avatar" alt="" />
                  </div>
                  <div class="sides-widget-details">
                    <h4>
                      <a href="s#">{{ property.posted_by.name }}</a>
                    </h4>
                    <span><i class="lni lni-envelope"></i>Send Message</span>
                  </div>
                  <div class="clearfix"></div>
                </div>

                <form @submit.prevent="sendMessage">
                  <div class="sides-widget-body simple-form" v-if="! sending_message">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Email"
                        v-model="message.email"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Phone No.</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Phone"
                        v-model="message.phone_number"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea
                        class="form-control"
                        v-model="message.message"
                        placeholder="I'm interested in this property."
                        required
                      ></textarea>
                    </div>
                    <button class="btn btn-black btn-md rounded full-width">
                      Send Message
                    </button>
                  </div>
                  <loading v-if="sending_message" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="list-layout">
          <similar-properties
            :region="property.region_slug"
            :type="property.type == 'sale' ? 'buy' : 'rent'"
            :property_id="property.id"
          />
        </div>
      </div>
    </section>

    <div class="modal fade" id="getPhoneNumber" tabindex="-1" aria-labelledby="getPhoneNumberLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="getPhoneNumberLabel">Get Phone Number</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="py-3 px-4">
            <alert type="info"
              >This information is shared with the property owner to enable
              easier communication. Read our <privacy-policy-url /></alert
            >
            <form @submit.prevent="getContacts('phone_number')">
              <div class="form-group">
                <label>Your Name</label>
                <input type="text" placeholder="Name" v-model="user.name" class="form-control" required>
              </div>
              <div class="form-group">
                <label>Your Phone Number</label>
                <input type="text" placeholder="Phone Number" v-model="user.phone_number" class="form-control" required>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="getWhatsapp" tabindex="-1" aria-labelledby="getWhatsappLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="getWhatsappLabel">Get Whatsapp Number</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="py-3 px-4">
            <alert type="info"
              >This information is shared with the property owner to enable
              easier communication. Read our <privacy-policy-url /></alert
            >
            <form @submit.prevent="getContacts('whatsapp')">
              <div class="form-group">
                <label>Your Name</label>
                <input type="text" placeholder="Name" v-model="user.name" class="form-control" required>
              </div>
              <div class="form-group">
                <label>Your Phone Number</label>
                <input type="text" placeholder="Phone Number" v-model="user.whatsapp" class="form-control" required>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="getEmail" tabindex="-1" aria-labelledby="getEmailLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-3">
            <h5 class="modal-title" id="getEmailLabel">Get Email</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="py-3 px-4">
            <alert type="info"
              >This information is shared with the property owner to enable
              easier communication. Read our <privacy-policy-url /></alert
            >
            <form @submit.prevent="getContacts('email')">
              <div class="form-group">
                <label>Your Name</label>
                <input type="text" placeholder="Name" v-model="user.name" class="form-control" required>
              </div>
              <div class="form-group">
                <label>Your Email</label>
                <input type="text" placeholder="Email" v-model="user.email" class="form-control" required>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import SimilarProperties from "./components/SimilarProperties.vue";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    SimilarProperties,
  },

  mounted() {
    this.$magnific.set("view-photos", this.property.images);

    if (this.$route.query.bookmark) {
      this.toggleBookmark();
      this.$router.push({ query: {} });
    }
  },

  data() {
    return {
      hooperSettings: {
        itemsToShow: 2,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
        breakpoints: {
          768: {
            itemsToShow: 1,
          },
          1024: {
            itemsToShow: 2,
          },
        },
      },
      message: {},
      contacts: {},
      show_contacts: true,
      user: {},
      sending_message: false
    };
  },

  props: ["property"],

  methods: {
    next() {
      window.location.href = this.$router.resolve({
        query: {
          bookmark: 1,
        },
      }).href;
    },

    sendMessage() {
      this.sending_message = true

      this.$axios
        .post(
          `/api/v1/properties/${this.property.uniqid}/send-message`,
          this.message
        )
        .then(() => {
          this.message = {};
          this.sending_message = false
          this.$swal('Your message has been sent.', '', 'success')
        });
    },

    toggleBookmark() {
      if (this.$auth.hasToken()) {
        this.$axios
          .post(
            `/api/v1/properties/${this.$route.params.slug}/bookmark`,
            this.message
          )
          .then((response) => {
            this.property.bookmarked = response.data.bookmarked;
          });
      } else {
        this.$router.push({ name: "login" });
      }
    },

    getContacts(type) {
      let data = {
        type: type,
        name: this.user.name,
        content: this.user[type]
      }

      this.$axios
        .post(`/api/v1/properties/${this.property.uniqid}/get-contact`, data)
        .then((response) => {
          this.show_contacts = false;
          this.contacts[type] = response.data[type];
          this.show_contacts = true;
        });
    },

    openPopup(image) {
      this.$magnific.open(image, this.property.images);
    },
  }
};
</script>
